





















































































import Vue from 'vue'
import VSelect from '@/components/BaseComponents/VSelect/VSelect.vue'
import { OPERATORS_TITLE } from '@/constants/FbAutomatedRule'
import { symbols } from '@/models/currencyCodes'

export default Vue.extend({
  name: 'AutoPostBoostingConditionItem',
  components: {
    VSelect
  },
  props: {
    evaluatedFields: {
      type: Array
    },
    rule: {
      required: true,
      type: Object
    },
    ruleId: {
      required: true
    },
    condition: {
      required: true,
      type: Object
    },
    conditionGroup: {
      required: true,
      type: Object
    },
    demoRule: {
      required: false,
      default: false,
      type: Boolean
    },
    groupId: {
      required: true
    },
    level: {
      required: true,
      type: Number
    },
    errors: {
      type: Object
    }
  },
  data () {
    return {
      operators: OPERATORS_TITLE,
      operatorTitle: '',
      itemId: this.condition.uid,
      operator: null
    }
  },
  computed: {
    metric () {
      return this.evaluatedFields.map((elem) => {
        return elem.fields.map(el => ({ ...el, label: el.name }))
      }).flat(1)
    },
    selectedMetric: {
      get () {
        return this.metric.find(_ => _.field === this.condition.metric)
      },
      set (v: { field }) {
        this.setBoostingCondition({
          itemId: this.condition.uid,
          type: 'metric',
          value: v.field
        })
      }
    },
    first_value: {
      get () {
        let v = 0
        if (Array.isArray(this.condition.value)) {
          v = this.condition.value[0] || 0
        } else {
          v = this.condition.value || 0
        }
        return isNaN(v) ? 0 : v
      },
      set (v: string | number): void {
        this.setBoostingCondition({
          itemId: this.condition.uid,
          type: 'value',
          value: this.operator_range ? [Number(Array.isArray(v) ? v[0] : v), Number(this.second_value)] : v
        })
      }
    },
    second_value: {
      get () {
        let v = 0
        if (Array.isArray(this.condition.value)) {
          v = this.condition.value[1] || 0
        } else {
          v = this.condition.value || 0
        }
        return isNaN(v) ? 0 : v
      },
      set (v: string | number): void {
        this.setBoostingCondition({
          itemId: this.condition.uid,
          type: 'value',
          value: [Number(this.first_value), Number(v)]
        })
      }
    },
    valueType () {
      return this.selectedMetric && this.selectedMetric.type
        ? this.selectedMetric.type
        : 'numeric'
    },
    time_frame_available () {
      if (this.selectedMetric) {
        return this.selectedMetric.time_range
      } else {
        return true
      }
    },
    operator_range () {
      return this.operator.value === 'NOT_IN_RANGE' || this.operator.value === 'IN_RANGE'
    },
    suitableOperators () {
      const res = []
      for (const j in this.operators[this.valueType]) {
        res.push({
          value: j,
          label: this.operators[this.valueType][j]
        })
      }
      return res
    },
    currency () {
      const adAccount = this.rule.ad_account_id
      if (this.valueType === 'percent') return '%'
      if (this.valueType === 'money') {
        if (!adAccount || !adAccount.currency) return '$'
        return symbols.find(
          c =>
            c.abbreviation.toLowerCase() === adAccount.currency.toLowerCase()
        ).symbol
      }
      return null
    }
  },
  methods: {
    addDefaultCondition (): void {
      this.$emit('add-default-condition')
    },
    deleteBoostCondition (condition: unknown): void {
      this.$emit('delete-boost-condition', condition)
    },
    setBoostingCondition (condition_setter: unknown): void {
      this.$emit('set-boosting-condition', condition_setter)
    },
    deleteCondition (): void {
      this.deleteBoostCondition({
        groupId: this.groupId,
        itemId: this.condition.uid,
        level: this.level
      })
      if (!Object.keys(this.rule.conditions.conditions).length) {
        this.addDefaultCondition()
      }
    },
    setOperator (op): void {
      this.operator = op
      this.operatorTitle = op.label
      this.setBoostingCondition({
        itemId: this.condition.uid,
        type: 'operator',
        value: op.value
      })
    }
  },
  created () {
    if (this.condition) {
      this.setOperator(this.suitableOperators.find((el) => el.value === this.condition.operator))
      this.first_value = this.condition.value
    }
  }
})
