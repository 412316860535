































import AutoPostBoostingCondition from '@/components/AutoPostBoosting/condition/AutoPostBoostingCondition.vue'
import { CONDITION_TYPE_CONDITION, CONDITION_TYPE_GROUP } from '@/constants/FbAutomatedRule'
import { generateConditionWithId } from '@/utils/autoPostBoostingConditions'
import Vue from 'vue'

export default Vue.extend({
  name: 'AutoPostBoostingConditionController',
  components: { AutoPostBoostingCondition },
  computed: {
    operator () {
      return this.group && this.group.operator
    },
    group () {
      return this.data && this.data.conditions
    }
  },
  props: {
    evaluatedFields: {
      type: Array
    },
    data: {
      type: Object
    },
    ruleId: {
      type: String
    },
    errors: {
      type: Object
    }
  },
  methods: {
    addDefaultCondition () {
      const id = Date.now()
      Vue.set(this.data.conditions.conditions, id, generateConditionWithId(id))
    },
    addDefaultGroup (payload) {
      const id = Date.now()
      const defaultGroup = {
        type: CONDITION_TYPE_GROUP,
        operator: 'AND',
        level: payload.level + 1,
        id,
        uid: id,
        conditions: {
          [id + 1]: generateConditionWithId(id + 1),
          [id + 2]: generateConditionWithId(id + 2)
        }
      }

      function addGroupRecursive (obj, groupId) {
        for (const prop in obj) {
          if (obj[prop].conditions && String(prop) !== String(groupId)) {
            addGroupRecursive(obj[prop].conditions, groupId)
          } else if (String(prop) === String(groupId)) {
            Vue.set(obj[prop].conditions, id, defaultGroup)
          }
        }
      }
      if (payload.level === 0) {
        Vue.set(this.data, 'conditions', {
          ...defaultGroup,
          id,
          uid: id,
          conditions: {
            [id + 1]: generateConditionWithId(id + 1)
          }
        })
        console.log('check')
      }

      if (Number(payload.level) === 1) {
        Vue.set(this.data.conditions.conditions, id, defaultGroup)
      } else {
        addGroupRecursive(this.data.conditions.conditions, payload.groupId)
      }
    },
    setGroupProp ({ groupId, type, value, level }) {
      this.$emit('remove-error', `conditions.${groupId}`)
      function setPropsRecursive (obj, groupId) {
        for (const prop in obj) {
          if (String(prop) === String(groupId)) {
            Vue.set(obj[prop], type, value)
          } else if (obj[prop].type !== CONDITION_TYPE_CONDITION) {
            setPropsRecursive(obj[prop].conditions, groupId)
          }
        }
      }

      if (level === 1) Vue.set(this.data.conditions, type, value)
      else setPropsRecursive(this.data.conditions.conditions, groupId)
    },
    deleteBoostCondition (payload) {
      const deleteGroupRecursive = function (obj, groupId, self) {
        self.$emit('remove-error', `conditions.${groupId}`)
        for (const prop in obj) {
          if (String(prop) === String(groupId)) {
            Vue.delete(obj, prop)
          } else if (obj[prop].type === CONDITION_TYPE_GROUP) {
            deleteGroupRecursive(obj[prop].conditions, groupId, self)
          }
        }
      }

      const deleteItemRecursive = function (obj, itemId, self) {
        self.$emit('remove-error', `conditions.${itemId}`)
        for (const prop in obj) {
          if (obj[prop].type !== CONDITION_TYPE_CONDITION) {
            deleteItemRecursive(obj[prop].conditions, itemId, self)
          } else if (String(prop) === String(itemId)) {
            Object.keys(obj).length === 1
              ? deleteGroupRecursive(this.data.conditions.conditions, payload.groupId, self)
              : Vue.delete(obj, prop)
          }
        }
      }
      deleteItemRecursive(this.data.conditions.conditions, payload.itemId, this)
    },
    setBoostingCondition (payload) {
      function setPropsRecursive (obj, itemId, self) {
        self.$emit('remove-error', `conditions.${itemId}`)
        for (const prop in obj) {
          if (obj[prop] && obj[prop].type !== CONDITION_TYPE_CONDITION) {
            setPropsRecursive(obj[prop].conditions, itemId, self)
          } else if (String(prop) === String(itemId)) {
            Vue.set(obj[prop], payload.type, payload.value)
          }
        }
      }
      setPropsRecursive(this.data.conditions.conditions, payload.itemId, this)
    }
  }
})
