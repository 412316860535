








































































































import type { AutoPostBoostingFiltersData } from '@/views/AutoPostBoosting/steps/AutoPostBoostingFilters.vue'
import Vue, { PropType } from 'vue'
import NewUiAccordion from '@/components/NewUIComponents/NewUiAccordion.vue'

export default Vue.extend({
  name: 'AutoPostBoostingMatchingResults',
  components: { NewUiAccordion },
  props: {
    posts: {
      type: Array as PropType<AutoPostBoostingFiltersData['posts']>
    },
    postsValidating: {
      type: Boolean,
      required: true
    }
  }
})
