
















































































































import AutoPostBoostingConditionItem from '@/components/AutoPostBoosting/condition/AutoPostBoostingConditionItem.vue'
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { CONDITION_TYPE_CONDITION, CONDITION_TYPE_GROUP } from '@/constants/FbAutomatedRule'

export default Vue.extend({
  name: 'AutoPostBoostingCondition',
  components: { AutoPostBoostingConditionItem },
  props: {
    evaluatedFields: {
      type: Array
    },
    rule: {
      required: true,
      type: Object
    },
    ruleId: {
      required: true
    },
    group: {
      required: true,
      type: Object
    },
    level: {
      required: true,
      type: Number
    },
    operator: {
      required: true,
      type: String
    },
    errors: {
      type: Object
    }
  },
  data () {
    return {
      switcherDropdown: false,
      conditions: this.group.conditions,
      groupId: this.group.uid,
      numberRule: 1
    }
  },
  computed: {
    ...mapGetters(['get_width']),
    isSingleRule () {
      return !!Object.values(this.rule.conditions.conditions).filter((v: { conditions }) => !!v.conditions).length
    }
  },
  methods: {
    addDefaultCondition (...args) {
      this.$emit('add-default-condition', ...args)
    },
    addDefaultGroup (...args) {
      this.$emit('add-default-group', ...args)
    },
    setGroupProp (...args) {
      this.$emit('set-group-prop', ...args)
    },
    deleteBoostCondition (...args) {
      this.$emit('delete-boost-condition', ...args)
    },
    setBoostingCondition (...args) {
      this.$emit('set-boosting-condition', ...args)
    },
    conditionTypeCondition (condition) {
      return condition.type === CONDITION_TYPE_CONDITION
    },
    conditionTypeGroup (condition) {
      return condition.type === CONDITION_TYPE_GROUP
    },
    addCondition () {
      this.addDefaultCondition()
    },
    addGroup () {
      if (this.level !== 4) {
        this.addDefaultGroup({
          ruleId: this.rule.id,
          groupId: this.groupId,
          level: this.level
        })
      }
    },
    editGroupOperator (value) {
      this.setGroupProp({
        groupId: this.groupId,
        level: this.level,
        type: 'operator',
        value
      })
    }
  }
})
