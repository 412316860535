

























































































import AutoPostBoostingErrors from '@/components/AutoPostBoosting/AutoPostBoostingErrors.vue'
import AutoPostBoostingMatchingResults from '@/components/AutoPostBoosting/AutoPostBoostingMatchingResults.vue'
import AutoPostBoostingConditionController
  from '@/components/AutoPostBoosting/condition/AutoPostBoostingConditionController.vue'
import BaseSelect from '@/components/BaseComponents/VSelect/VSelect.vue'
import NewUiSpinner from '@/components/NewUIComponents/NewUiSpinner.vue'
import PostBoostingService from '@/services/api/PostBoostingService'
import props from '@/views/AutoPostBoosting/steps/props'
import { AxiosResponse } from 'axios'
import Jsona from 'jsona'
import Vue from 'vue'
import { mapActions } from 'vuex'

export type AutoPostBoostingFiltersData = {
  evaluatedFields: unknown | null,
  postTypes: unknown | null,
  posts: unknown | null,
  postsValidating: boolean
}

const jsona = new Jsona()
const POST_TYPES_REQUIRED_ERROR_MESSAGE = 'Post type is required!'

export default Vue.extend({
  name: 'AutoPostBoostingFilters',
  components: {
    NewUiSpinner,
    AutoPostBoostingMatchingResults,
    AutoPostBoostingErrors,
    BaseSelect,
    AutoPostBoostingConditionController
  },
  mixins: [props],
  data (): AutoPostBoostingFiltersData {
    return {
      evaluatedFields: null,
      postTypes: null,
      posts: null,
      postsValidating: false
    }
  },
  computed: {
    postTypesRecord (): Record<string, string> {
      return this.postTypes?.reduce(($, _) => ($[_.value] = _.label) && $, {}) || {}
    },
    conditions: {
      get (): unknown {
        return this.data?.conditions
      },
      set (value: unknown) {
        this.data.conditions = value
      }
    },
    dataPostTypes: {
      get (): Array<string> {
        return this.data?.post_types || []
      },
      set (value: string) {
        this.$emit('remove-error', 'post_types')
        if (this.data) {
          if (!this.data.post_types) {
            this.data.post_types = []
          }
          this.data.post_types = this.data.post_types.includes(value)
            ? this.data.post_types.filter(_ => _ !== value)
            : this.data.post_types.push(value) && this.data.post_types
        }
      }
    },
    operator (): string | undefined {
      return this.conditions?.operator
    }
  },
  methods: {
    ...mapActions('notifications', ['addNotification']),
    async getEvaluatedFields () {
      const axiosResponse: AxiosResponse = await PostBoostingService.getEvaluationFields(this.service)
      if (axiosResponse?.data?.data) {
        this.evaluatedFields = axiosResponse.data.data
      }
    },
    async getPostTypes () {
      const axiosResponse: AxiosResponse = await PostBoostingService.getPostTypes(this.service)
      if (Array.isArray(axiosResponse?.data?.data)) {
        this.postTypes = axiosResponse.data.data.map(_ => ({ label: _.title, ..._ }))
      }
    },
    selectPostType ({ value }: { value: string }) {
      this.dataPostTypes = value
    },
    frontEndErrorsHandler (): boolean {
      if (!this.dataPostTypes.length) {
        this.$emit('set-error', 'post_types', 'Field is required!')
        return true
      }
      return false
    },
    getDataToPatch (): unknown {
      return {
        post_types: this.dataPostTypes || [],
        conditions: this.data.conditions
      }
    },
    async patch (): Promise<AxiosResponse> {
      return PostBoostingService.updatePostBoostingRule(this.service, this.getDataToPatch(), this.ruleId)
    },
    createSearchPostsData (): { filters: Array<unknown> } {
      if (!this.data.post_types || this.data.post_types.length === 0) {
        throw new Error(POST_TYPES_REQUIRED_ERROR_MESSAGE)
      }

      const hasValidCondition = this.data.conditions?.conditions &&
        Object.values(this.data.conditions.conditions)[0]?.['metric']

      const conditions = hasValidCondition
        ? [
          {
            field: 'conditions',
            predicate: 'MATCH',
            value: {
              conditions: this.data.conditions
            }
          }
        ]
        : []

      const result = {
        filters: [
          {
            field: 'type',
            predicate: 'IN',
            value: this.data.post_types
          },
          ...conditions
        ]
      }

      return result
    },
    async searchPosts (): Promise<AxiosResponse> {
      return PostBoostingService.searchPosts(this.service, this.createSearchPostsData(), this.ruleId)
    },
    async validatePostFilters (): Promise<void> {
      this.postsValidating = true
      try {
        const axiosResponse = await this.searchPosts()
        this.posts = jsona.deserialize(axiosResponse.data).map(_ => ({ ..._, collapsed: true }))
      } catch (error) {
        if (error instanceof Error && error.message === POST_TYPES_REQUIRED_ERROR_MESSAGE) {
          this.addNotification({ body: 'Post types required!', type: 'danger' }).then()
        }
      } finally {
        if (this.posts === null) {
          this.posts = []
        }
        this.postsValidating = false
      }
    }
  },
  created (): void {
    if (this.ruleId) {
      this.$emit('reload-rule', () => {
        this.getEvaluatedFields()
        this.getPostTypes()
      })
    } else {
      this.addNotification({ body: 'The rule has not been created yet!', type: 'danger' })
      this.$router.replace({
        name: 'PostBoostingNewDetails',
        params: { ...this.$route.params }
      })
    }
  }
})

